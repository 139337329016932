
    import Component from 'vue-class-component'
    import Vue from 'vue'

    import ComponentSettingsTags from '@/components/settings/ComponentSettingsTags.vue'
    import ComponentDaemons from '@/components/settings/ComponentDaemons.vue'
    import ComponentAccountSettings from '@/components/settings/ComponentAccountSettings.vue'

    @Component({
        components: { ComponentDaemons, ComponentSettingsTags, ComponentAccountSettings }
    })
    export default class PageSettings extends Vue {
        panels: Array<number> = [0, 1, 2, 3]
    }
