
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Accounts from '@/store/modules/accounts'
    import { Account } from '@/models/data/account'
    import Notifications from '@/lib/notifications'
    import { DateTime } from 'luxon'
    import AccountRepository from '@/repositories/account-repository'
    import { cloneDeep } from 'lodash'

    @Component
    export default class ComponentAccountSettings extends Vue {
        accountsModule = getModule(Accounts, this.$store)

        loading = true
        updating = false
        gmailRedirecting = false
        selectedAccount: Account = new Account()
        expandedAccounts: Array<Account> = []

        // region static data
        imapProtocols = [
            {
                text: 'IMAP',
                value: 'imap'
            }
        ]

        imapEncryptionTypes = [
            {
                text: 'None',
                value: false
            },
            {
                text: 'SSL',
                value: 'ssl'
            },
            {
                text: 'TLS',
                value: 'tls'
            }
        ]

        smtpAuthenticationMethods = [
            {
                text: 'Klasyczna',
                value: 'basic'
            },
            {
                text: 'OAuth',
                value: 'oauth'
            }
        ]

        headers = [
            {
                text: 'Nazwa',
                value: 'name'
            },
            {
                text: 'Opis',
                value: 'description'
            },
            {
                text: 'Podpis',
                value: 'signature'
            },
            {
                text: 'Dodano',
                value: 'created_at'
            },
            {
                text: 'Zaktualizowano',
                value: 'updated_at'
            },
            {
                text: 'Testuj IMAP',
                value: 'test.imap'
            },
            {
                text: 'Testuj SMTP',
                value: 'test.smtp'
            }
        ]
        // endregion

        // region getters
        get accounts (): Array<Account> {
            return this.accountsModule.entries.data
        }
        // endregion

        // region utility methods
        formatDate (date: string): string {
            return DateTime.fromISO(date).toFormat('yyyy.MM.dd HH:mm')
        }

        onExpanded (expanded: { item: Account, value: boolean }): void {
            if (expanded.value) {
                this.selectedAccount = cloneDeep(expanded.item)
            } else {
                this.selectedAccount = new Account()
            }
        }

        cancelUpdate (item: Account): void {
            this.selectedAccount = cloneDeep(item)
            this.expandedAccounts = []
        }
        // endregion

        // region actions
        async testImap (account: Account): Promise<void> {
            await this.accountsModule.testImap(account)
        }

        async testSmtp (account: Account): Promise<void> {
            await this.accountsModule.testSmtp(account)
        }

        async redirectToGmail (account: Account): Promise<void> {
            this.gmailRedirecting = true
            const uri = await AccountRepository.gmailRedirect(account)

            window.open(uri, '_blank')
            this.gmailRedirecting = false
        }

        async update (): Promise<void>{
            this.updating = true
            try {
                await this.accountsModule.update(this.selectedAccount)
                Notifications.success('Zaktualizowano konto')
            } catch {
                Notifications.error('Nie udało się zaktualizować konta')
            } finally {
                this.updating = false
                this.expandedAccounts = []
            }
        }

        async loadAccounts (): Promise<void> {
            this.loading = true
            try {
                await this.accountsModule.loadPage()
            } catch (e) {
                Notifications.error('Wystąpił problem przy ładowaniu kont. Odśwież stronę lub skontaktuj się z administracją.')
            }
            this.loading = false
        }
        // endregion

        // region lifecycle
        async mounted (): Promise<void> {
            await this.loadAccounts()
        }
        // endregion
    }
