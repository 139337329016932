
    import Vue from 'vue'

    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import { DateTime } from 'luxon'
    import Notification from '@/lib/notifications'
    import { DataTableHeader } from 'vuetify'

    import Mode from '@/store/mode'
    import Tags from '@/store/modules/tags'
    import { Tag } from '@/models/data/tag'

    @Component
    export default class ComponentSettingsTags extends Vue {
        tagsModule = getModule(Tags, this.$store)
        dialog = false
        saving = false
        search = ''
        swatches: Array<Array<string>> = [
            ['#F44336', '#E91E63', '#9C27B0', '#673AB7'],
            ['#3F51B5', '#2196F3', '#03A9F4', '#00BCD4'],
            ['#009688', '#4CAF50', '#8BC34A', '#CDDC39'],
            ['#FFEB3B', '#FFC107', '#FF9800', '#FF5722'],
            ['#795548', '#607D8B', '#9E9E9E', '#000000'],
        ]
        headers: Array<DataTableHeader> = [
            {
                text: this.$t('crud.name') as string,
                value: 'name'
            },
            {
                text: this.$t('crud.color') as string,
                value: 'color'
            },
            {
                text: this.$t('crud.created_at') as string,
                value: 'created_at'
            },
            {
                text: this.$t('crud.updated_at') as string,
                value: 'updated_at'
            },
            {
                text: '',
                value: 'delete'
            }
        ]

        // region Field mappings
        get tags (): Array<Tag> {
            return this.tagsModule.entries
        }

        get name (): string {
            return this.getProperty('name')
        }

        set name (value: string) {
            this.tagsModule.setProperty({ key: 'name', value: value })
        }

        get color (): string {
            return this.getProperty('color')
        }

        set color (value: string) {
            this.tagsModule.setProperty({ key: 'color', value })
        }
        // endregion

        // region utils
        getProperty<V extends keyof Tag> (key: V): Tag[V] {
            if (this.tagsModule.mode === Mode.CREATE) {
                return this.tagsModule.newEntry[key]
            } else {
                return this.tagsModule.selectedEntry[key]
            }
        }

        edit (tag: Tag): void {
            this.tagsModule.setSelectedEntry(tag)
            this.tagsModule.setMode(Mode.EDIT)
        }

        add (): void {
            this.tagsModule.setMode(Mode.CREATE)
            this.dialog = true
        }

        close (): void {
            this.saving = false
            this.dialog = false
        }

        formatDate (date: string): string {
            return DateTime.fromISO(date).toFormat('yyyy.MM.dd HH:mm')
        }
        // endregion

        // region actions
        async update (): Promise<void> {
            this.saving = true
            await this.tagsModule.update().catch(() => {
                this.saving = false
                Notification.error(this.$t('crud.error.update') as string)
            })
            this.close()
            Notification.success(this.$t('crud.success.updated') as string)
        }

        async create (): Promise<void> {
            this.saving = true
            await this.tagsModule.create().catch(() => {
                this.saving = false
                Notification.error(this.$t('crud.error.create') as string)
            })
            this.close()
            Notification.success(this.$t('crud.success.created') as string)
        }

        async remove (tag: Tag): Promise<void> {
            this.tagsModule.remove(tag).catch(() => {
                this.saving = false
                Notification.error(this.$t('crud.error.delete') as string)
            })
            Notification.info(this.$t('crud.success.deleted') as string)
        }
        // endregion
    }
