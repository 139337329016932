
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import Notifications from '@/lib/notifications'
    import HttpClient from '@/lib/http-client'
    import { daemons as Api } from '@/api'
    import { IDaemonLog } from '@/models/data/daemon-log'
    import { DateTime } from 'luxon'

    @Component
    export default class ComponentDaemons extends Vue {
        daemons = [
            {
                name: 'CleanUnusedSorterChildrenJob',
                prettyName: 'Czyszczenie nieużywanych podzbiorów sortera',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'CleanUnusedTradingChildrenJob',
                prettyName: 'Czyszczenie nieużywanych podzbiorów systemu sprzedaży',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'FindMissingMessagesJob',
                prettyName: 'Dopasowywanie niekomplementarnych wiadomości',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'IndexImapMessagesJob',
                prettyName: 'Indeksowanie wiadomości',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'AssignContractorToMessageJob',
                prettyName: 'Rozpoznawanie kontrahentów',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'AssignOrderToMessageJob',
                prettyName: 'Rozpoznawanie zamówień',
                lastRun: 'nigdy',
                loading: false
            },
            {
                name: 'SortImapMessagesJob',
                prettyName: 'Sortowanie wiadomości',
                lastRun: 'nigdy',
                loading: false
            }
        ]

        async run (daemon: { name: string, prettyName: string, lastRun: string, loading: boolean }): Promise<void> {
            daemon.loading = true

            try {
                Notifications.info('Zadanie zostało uruchomione.')
                await HttpClient.getJson(Api.run(daemon.name))
                Notifications.success('Zadanie zostało wykonane.')
            } catch (e) {
                Notifications.error('Wystąpił problem w trakcie wykonywania zadania.')
            } finally {
                await this.loadDaemonsRunTimes()
                daemon.loading = false
            }
        }

        async loadDaemonsRunTimes (): Promise<void> {
            const response = await HttpClient.getJson<Array<IDaemonLog>>(Api.runTimes)

            this.daemons.map(daemon => {
                daemon.lastRun = response.find(x => x.name === daemon.name)?.finished_at || 'nigdy'
            })
        }

        formatDate (date: string): string {
            const dt = DateTime.fromISO(date)
            return dt.isValid ? dt.toFormat('yyyy.MM.dd HH:mm:ss') : 'nigdy'
        }

        mounted (): void {
            this.loadDaemonsRunTimes()
        }
    }
